import { useCallback, useEffect } from 'react';
import _ from '@/lodash';
import { useSearchParams } from 'react-router-dom';

// https://vite.dev/guide/build#load-error-handling
// https://github.com/vitejs/vite/issues/11804
export default function HandleReload() {
  const [searchParams, setSearchParams] = useSearchParams();
  const maxRetries = 3;

  const handleReload = useCallback(
    (event: any) => {
      const retries = _.toNumber(searchParams.get('retries')) || 0;

      if (retries < maxRetries) {
        searchParams.set('retries', _.toString(retries + 1));
        setSearchParams(searchParams);

        event.preventDefault();
        window.location.reload();
      }
    },
    [searchParams, setSearchParams],
  );

  useEffect(() => {
    window.addEventListener('vite:preloadError', handleReload);
    return () => window.removeEventListener('vite:preloadError', handleReload);
  }, [handleReload]);

  return null;
}
