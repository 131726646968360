import { Navigate, useLocation } from 'react-router-dom';
import { useDefaultLink } from '@/helpers';
import { useLoginSelector } from '@/redux/selectors';

function DefaultRedirect() {
  const defaultLink = useDefaultLink();
  const { pathname } = useLocation();
  const login = useLoginSelector();

  if (pathname === defaultLink) {
    return null;
  }

  return login?.id ? <Navigate to={defaultLink} /> : <></>;
}

export default DefaultRedirect;
