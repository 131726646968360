import { mapValues } from 'lodash-es';
import { omit } from 'lodash-es';
import { sortBy } from 'lodash-es';
import { orderBy } from 'lodash-es';

import { find } from 'lodash-es';
import { map } from 'lodash-es';
import { filter } from 'lodash-es';
import { isEqual } from 'lodash-es';
import { isString } from 'lodash-es';

import { sampleSize } from 'lodash-es';
import { without } from 'lodash-es';
import { includes } from 'lodash-es';
import { uniq } from 'lodash-es';
import { truncate } from 'lodash-es';
import { isEmpty } from 'lodash-es';
import { size } from 'lodash-es';
import { each } from 'lodash-es';
import { split } from 'lodash-es';
import { trim } from 'lodash-es';
import { slice } from 'lodash-es';
import { debounce } from 'lodash-es';
import { values } from 'lodash-es';
import { range } from 'lodash-es';
import { kebabCase } from 'lodash-es';
import { compact } from 'lodash-es';
import { first } from 'lodash-es';
import { take } from 'lodash-es';
import { isArray } from 'lodash-es';
import { flatten } from 'lodash-es';
import { concat } from 'lodash-es';
import { toString } from 'lodash-es';
import { toLower } from 'lodash-es';
import { toInteger } from 'lodash-es';
import { replace } from 'lodash-es';
import { times } from 'lodash-es';
import { toNumber } from 'lodash-es';
import { join } from 'lodash-es';
import { max } from 'lodash-es';
import { sum } from 'lodash-es';

const _ = {
  map,
  includes,
  size,
  first,
  slice,
  uniq,
  compact,
  truncate,
  trim,
  find,
  each,
  take,
  isArray,
  flatten,
  concat,
  omit,
  toString,
  split,
  mapValues,
  isEmpty,
  sortBy,
  toLower,
  kebabCase,
  isEqual,
  toInteger,
  sampleSize,
  filter,
  orderBy,
  values,
  without,
  replace,
  times,
  toNumber,
  range,
  join,
  isString,
  debounce,
  max,
  sum,
};

export default _;
