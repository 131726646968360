import { configureStore } from '@reduxjs/toolkit';
import { loginReducer, collectionsReducer } from './reducers';
import { captureException } from '@sentry/react';

const store = configureStore({
  reducer: { login: loginReducer, collections: collectionsReducer },
});

let backupStore: any = {};
const safeStorage = getSafeStorage();

function persistLogin() {
  const { id, url_tag, short_id } = store.getState().login;

  if (id) {
    safeStorage.setItem('id', id);
    safeStorage.setItem('last_seen', new Date().toString());

    if (short_id) {
      safeStorage.setItem('short_id', short_id);
    }

    if (url_tag) {
      safeStorage.setItem('url_tag', url_tag);
    }
  } else {
    safeStorage.clear();
  }
}

// https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage#securityerror
export function getSafeStorage() {
  let safeStorage;

  try {
    localStorage.setItem('test_availability', 'true');
    localStorage.removeItem('test_availability');
    safeStorage = localStorage;
  } catch (localError) {
    try {
      sessionStorage.setItem('test_availability', 'true');
      sessionStorage.removeItem('test_availability');
      safeStorage = sessionStorage;
    } catch (sessionError) {
      safeStorage = {
        setItem: (key: string, value: string) => (backupStore[key] = value),
        getItem: (key: string) => backupStore[key],
        removeItem: (key: string) => delete backupStore[key],
        clear: () => (backupStore = {}),
      };

      captureException(new Error('No storage available.'), {
        extra: { localError, sessionError },
      });
    }
  }

  return safeStorage;
}

persistLogin();

store.subscribe(persistLogin);

export default store;
