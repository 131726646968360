import { useCallback, useEffect } from 'react';

export default function SyncAuth() {
  const handleStorageChange = useCallback((event: any) => {
    if (event.newValue === 'logged_in' || event.newValue === 'logged_out') {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [handleStorageChange]);

  return null;
}
