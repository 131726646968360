import _ from '@/lodash';
import styles from './Radio.module.scss';
import { IoIosCheckmark } from 'react-icons/io';

interface RadioProps {
  allOptions: any[];
  inputProps?: any;
}

function Radio(props: RadioProps) {
  const { allOptions, inputProps = {} } = props;

  return (
    <div className={styles.radioRow}>
      {_.map(allOptions, ({ value, label }: any) => (
        <div className={styles.radioContainer}>
          <label className={styles.radioLabel}>
            <input
              className={styles.radioInput}
              type="radio"
              value={value}
              {...inputProps}
            />

            <div className={styles.radioInner}>
              <span className={styles.radioBox}>
                <IoIosCheckmark />
              </span>
              <span>{label}</span>
            </div>
          </label>
        </div>
      ))}
    </div>
  );
}

export default Radio;
