import { useDispatch } from 'react-redux';
import modalStyles from '@/components/Modal/Modal.module.scss';
import styles from './UpdateEmail.module.scss';
import Modal from '@/components/Modal/Modal';
import { Button } from '@/components/Button/Button';
import { fetchPost } from '@/hooks/useFetch';
import { FormInput } from '@/components/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import { useState } from 'react';

interface UpdateEmailProps {
  buttonChildren?: any;
}

function UpdateEmail(props: UpdateEmailProps) {
  const { buttonChildren } = props;

  const form = useForm();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const email = form.watch('email');

  const updateEmail = (formData: any) => {
    const { email, password } = formData;

    setIsLoading(true);
    setErrorMessage('');

    fetchPost('/auth/request_update', { email, password }, { dispatch })
      .then(() => setIsSuccess(true))
      .catch(() => {
        setErrorMessage(
          'Something went wrong. Please check your email, password, or try again later.',
        );
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      buttonChildren={buttonChildren}
      render={({ closeModal }) => (
        <div className={modalStyles.modalForm}>
          <div>
            <div className={modalStyles.modalHeader}>
              {isSuccess ? 'Email Sent' : 'Update Email'}
            </div>

            <div className={modalStyles.modalSubheader}>
              {isSuccess && (
                <div className={styles.verifyMessage}>
                  <div>
                    You will receive a verification link to complete this update
                    at your new email address at:
                  </div>

                  <div>{email}</div>

                  <div>
                    Please check your inbox for the verification link shortly.
                  </div>
                </div>
              )}

              {!isSuccess &&
                `This will change the email that you login with
                  and the email that new client messages are sent to.`}
            </div>
          </div>

          {!isSuccess && (
            <div className="form-field">
              <span className="form-label">New Email</span>
              <FormInput
                form={form}
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Your new email"
                maxLength={254}
                required
                data-private
              />
            </div>
          )}

          {!isSuccess && (
            <div className="form-field">
              <span className="form-label">Your Password</span>
              <FormInput
                form={form}
                name="password"
                type="password"
                placeholder="Your password"
                maxLength={50}
                required
                data-private
              />
            </div>
          )}

          {errorMessage && <div className="form-error">{errorMessage}</div>}

          <div className="form-actions">
            {isSuccess && (
              <Button
                className="btn"
                onClick={() => {
                  closeModal();
                  setIsSuccess(false);
                }}
                disabled={isLoading}
              >
                Got it
              </Button>
            )}

            {!isSuccess && (
              <>
                <Button
                  className="btn"
                  color="grey"
                  onClick={closeModal}
                  disabled={isLoading}
                >
                  Cancel
                </Button>

                <Button
                  className="btn"
                  onClick={form.handleSubmit(updateEmail)}
                  disabled={isLoading}
                >
                  {isLoading ? 'Loading...' : 'Save Changes'}
                </Button>
              </>
            )}
          </div>
        </div>
      )}
    />
  );
}

export default UpdateEmail;
